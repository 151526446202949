<template>
  <div>
    <div class="header">
      <div class="zuo">
        <div class="titlename">赛事报名></div>
        <div @click="$router.back(0)" class="titlename">赛事详情></div>
        <div class="current">{{ title }}</div>
      </div>
      <div class="but" @click="signup">
        <img src="../assets/image/viewlist.png" alt="">
        <p>查看我的报名</p>
      </div>
    </div>
    <div class="head">
      <div class="searchfor">
        <div style="display: flex;align-items: center;">
          <el-select placeholder="请选择" class="level" filterable v-model="info.groupName" clearable>
            <el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText" :value="item.itemValue">
            </el-option>
            <template slot="prefix">
              <div style="display: flex;">
                <span class="searchforlogo">
                  <img src="../assets/image/project2.png" alt="" srcset="">
                </span>
                <span class="title-name">组别：</span>
              </div>
            </template>
          </el-select>
          <div class="buts" @click="searchfor">
            <div class="img">
              <img src="../assets/image/searchfor.png" alt="">
            </div>
            <div class="text">搜索</div>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%;" :header-cell-style="{ background: '#0A6DF3' }"
          :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column type="index" label="序号" align="center" width="70" sortable>
          </el-table-column>
          <el-table-column prop="groupName" label="组别" align="center">
          </el-table-column>
          <el-table-column prop="projectName" label="项目" align="center">
          </el-table-column>
          <el-table-column label="性别" align="center">
            <template slot-scope="scope">
              {{ scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : '' }}
            </template>
          </el-table-column>
          <el-table-column label="出生日期" align="center">
            <template slot-scope="scope">
              {{ scope.row.birthDateStart }} ~ {{ scope.row.birthDateEnd }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" style="margin-right: 10PX;"
                @click="newlyadded(scope.row)">报名</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <registration ref="confirm"></registration>

  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
import registration from '@/components/registration.vue'
Vue.use(Meta)
export default {
  name: '',
  components: {
    registration
  },
  data() {
    return {
      title: '',
      tableData: [],
      info: {
        matchId: this.$route.query.matchId,
        groupName: ''
      },
      options1: []
    }
  },
  created() {
    this.title = this.$route.query.title
    this.getIdCardType()
    this.projectRegList()
  },
  computed() {

  },
  methods: {
    searchfor() {
      this.projectRegList()
    },
    projectRegList() {
      this.$api.projectRegList(this.info).then(res => {
        this.tableData = res.data.data.result.list
        const result = res.data.data.result.groupNames.map(name => ({ itemText: name, itemValue: name }));
        this.options1 = result
      })
    },
    getIdCardType() {
      this.$api.idCardType({ dictValue: '其他' }).then(res => {
        this.idCardType = res.data.data.result
      })
    },
    signup() {
      this.$router.push({
        path: '/registerIndex',
        query: {
          refresh: new Date().getTime(),
          menuIndex: 3,
        },
      })
    },
    newlyadded(row) {
      let data = {
        matchId: this.$route.query.matchId,
        groupName: row.groupName,
        projectName: row.projectName,
        settingsId: row.settingsId,
        projectId: row.id,
        projectSettingId: row.projectSettingsId,
        groupSettingId: row.groupSettingsId,
      }
      this.$refs.confirm.open(data)
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200PX;
  margin: 34PX auto 0;
  font-size: 18PX;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}

.zuo {
  display: flex;
}

.current {
  color: #0066FF;
}

.active {
  background: #0063FF !important;
  color: #FFFFFF !important;
}

.head {
  width: 1200PX;
  margin: 39PX auto;
  background: #F8FBFF;
  border-radius: 22PX;
  display: flex;
  flex-direction: column;
}

.but {
  width: 150PX;
  height: 35PX;
  background: #0A6DF3;
  border-radius: 4PX;
  font-size: 14PX;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 15PX;
    height: 15PX;
    margin-right: 5PX;
  }
}

.table {
  margin: 40PX;
}

.table ::v-deep .el-table thead {
  color: #fff;
  font-size: 16PX;
  font-weight: 500;
}

.table ::v-deep .el-table__header-wrapper {
  border-top-left-radius: 10PX;
  border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
  border-top-left-radius: 10PX;
  border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: 1PX solid #333333;
  border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #333333;
}

.searchfor {
  display: flex;
  justify-content: flex-start;
  margin: 40PX 0 0 40PX;
}

.searchfor ::v-deep .el-input__inner {
  width: 250PX;
  height: 43PX;
  background: #FFFFFF;
  border: 1PX solid #003680;
  border-radius: 7PX;
  font-size: 18PX;
  color: #333333;
  padding: 0 15PX 0 120PX;
}

.searchfor ::v-deep input::-webkit-input-placeholder {
  color: #333333;
}

.searchfor ::v-deep input::-moz-input-placeholder {
  color: #333333;
}

.searchfor ::v-deep input::-ms-input-placeholder {
  color: #333333;
}

.searchfor ::v-deep .el-select .el-input .el-select__caret {
  color: #191919;
}

.searchfor .searchforlogo {
  display: block;
  width: 28PX;
  height: 27PX;
  margin-top: 8PX;
  margin-left: 17PX;
}

.searchfor .searchforlogo img {
  width: 100%;
  height: 100%;
}

.searchfor .title-name {
  font-size: 18PX;
  font-weight: bold;
  color: #333333;
  margin-top: 9PX;
  margin-left: 13PX;
}

.searchfor .inp ::v-deep .el-input__inner {
  width: 201PX;
  height: 43PX;
}

.buts {
  width: 150PX;
  height: 43PX;
  background: #164B92;
  border-radius: 9PX;
  display: flex;
  align-items: center;
  margin-left: 29PX;
  cursor: pointer;
}

.buts .img {
  width: 21PX;
  height: 21PX;
  margin-left: 31PX;
}

.buts .text {
  font-size: 19PX;
  font-weight: bold;
  color: #FFFFFF;
  margin-left: 18PX;
  letter-spacing: 4PX;
}
</style>