<template>
  <div>
    <el-dialog title="选择报名人" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" top="10vh">
      <div class="box">
        <div class="but" @click="getadd(1, '')">
          <img src="../assets/image/people.png" alt="">
          <span>新增常用报名人</span>
        </div>
        <div class="group">
          <el-radio-group v-model="checkList">
            <div v-for="city in cities" :key="city.id" style="display: flex;">
              <el-radio :label="city.id">{{ city.title }}</el-radio>
              <div style="margin-top: 3PX;">
                <el-link type="primary" :underline="false" @click="getadd(2, city)">编辑</el-link>
                <el-link type="primary" :underline="false" style="margin-left: 10PX;"
                  @click="getdelete(city)">删除</el-link>
              </div>
            </div>
          </el-radio-group>
        </div>
        <div class="but_box">
          <div class="zou" @click="handleClose">返回</div>
          <div class="you" @click="confirm">确定</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="新增常用报名人" :visible.sync="dialogVisibles" width="50%" :before-close="handleCloses" top="15vh">
      <div class="boxb">
        <div class="boxs">
          <div class="img">
            <img src="../assets/image/user.png" alt="">
          </div>
          <div class="title">姓名<span class="xx">*</span></div>
          <div class="input">
            <el-input placeholder="请输入内容" v-model="info.name"></el-input>
          </div>
        </div>
        <div class="boxs">
          <div class="img">
            <img src="../assets/image/gender.png" alt="">
          </div>
          <div class="title">性别<span class="xx">*</span></div>
          <div class="input">
            <el-select placeholder="请选择" clearable v-model="info.sex">
              <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="boxs">
          <div class="img">
            <img src="../assets/image/ID.png" alt="">
          </div>
          <div class="title">证件类型<span class="xx">*</span></div>
          <div class="input">
            <el-select placeholder="请选择" v-model="info.certificateType">
              <el-option v-for="item in idCardType" :key="item.itemValue" :label="item.itemText"
                :value="item.itemValue">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="boxs">
          <div class="img">
            <img src="../assets/image/ID1.png" alt="">
          </div>
          <div class="title">证件号码<span class="xx">*</span></div>
          <div class="input">
            <el-input placeholder="请输入内容" v-model="info.idCard" @blur="getcardnum" @input="formatIdCard"
              maxlength="30"></el-input>
          </div>
        </div>
        <div class="boxs">
          <div class="img">
            <img src="../assets/image/222.png" alt="">
          </div>
          <div class="title">出生日期<span class="xx">*</span></div>
          <div class="input">
            <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
              v-model="info.birthday">
            </el-date-picker>
          </div>
        </div>
        <div class="boxs">
          <div class="img" style="width: 10PX;margin-right: 28PX;">
            <img src="../assets/image/tel.png" alt="">
          </div>
          <div class="title">手机号<span class="xx">*</span></div>
          <div class="input">
            <el-input placeholder="请输入内容" v-model="info.phone"></el-input>
          </div>
        </div>
        <div class="but_box">
          <div class="you" @click="getaddUserContact">提交</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      checkList: '',
      cities: [],
      dialogVisibles: false,
      idCardType: [],
      gender: [{
        label: '男',
        value: 1
      },
      {
        label: '女',
        value: 2
      }],
      info: {
        name: '',
        phone: '',
        sex: '',
        certificateType: '01',
        idCard: '',
        birthday: '',
        userId: ''
      },
      index: 0,
      editdistinction: 0
    }
  },
  computed: {},
  watch: {},
  methods: {
    getUserContact() {
      this.$api.getUserContact({ userId: this.$store.state.userid }).then(res => {
        res.data.data.result.forEach(item => {
          item.title = item.name + '，' + (item.sex == 1 ? '男' : '女') + '，' + this.$getUserCard(item.idCard)
        })
        this.cities = res.data.data.result
      })
    },
    open(row) {
      this.index = row
      this.dialogVisible = true
      this.getIdCardType()
      this.getUserContact()
    },
    handleClose() {
      this.dialogVisible = false
      this.checkList = ''
    },
    confirm() {
      if (this.$verification(this.checkList)) {
        return this.$message.error('请选择报名人')
      }
      for (let i in this.cities) {
        if (this.cities[i].id == this.checkList) {
          let data = {
            index: this.index,
            data: this.cities[i]
          }
          this.$emit('confirm', data)
          this.handleClose()
        }
      }
    },
    getadd(e, row) {
      this.editdistinction = e
      if (e == 2) {
        this.info = {
          name: row.name,
          phone: row.phone,
          sex: row.sex,
          certificateType: row.certificateType,
          idCard: row.idCard,
          birthday: row.birthday,
          userId: row.userId,
          id: row.id
        }
      }
      this.dialogVisibles = true
    },
    handleCloses() {
      this.dialogVisibles = false
      this.info = {
        name: '',
        phone: '',
        sex: '',
        certificateType: '01',
        idCard: '',
        birthday: '',
        userId: ''
      }
    },
    getIdCardType() {
      this.$api.idCardType({ dictValue: '其他' }).then(res => {
        this.idCardType = res.data.data.result
      })
    },
    formatIdCard() {
      this.info.idCard = this.info.idCard.replace(/x/gi, 'X')
    },
    getcardnum() {
      if (this.info.certificateType == '01') {
        this.info.birthday = this.getBirthdayAndGender(this.info.idCard).birthday
        this.info.sex = this.getBirthdayAndGender(this.info.idCard).gender
      }
    },
    // 验证身份证是否正确
    validateIdCard(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return false
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return false
      }

      // 统一转换为 18 位身份证号码
      let idCard18 = idCard;
      if (idCard.length === 15) {
        idCard18 = this.convertTo18IdCard(idCard);
      }

      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard18.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard18[17] !== checkCode[checkSum]) {
        return false
      }

      // 验证通过
      return true
    },
    // 验证身份证 出生日期 性别 
    getBirthdayAndGender(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return null;
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return null;
      }

      // 统一转换为 18 位身份证号码
      if (idCard.length === 15) {
        idCard = this.convertTo18IdCard(idCard);
      }
      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard[17] !== checkCode[checkSum]) {
        return null;
      }

      // 提取出生日期
      const birthDate = idCard.slice(6, 14);
      if (!/^\d{8}$/.test(birthDate)) {
        return null;
      }
      const year = parseInt(birthDate.slice(0, 4));
      const month = parseInt(birthDate.slice(4, 6));
      const day = parseInt(birthDate.slice(6, 8));

      // 提取性别
      const genderCode = parseInt(idCard[16]);
      const gender = genderCode % 2 === 0 ? 2 : 1;

      // 返回生日和性别信息
      return {
        birthday: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
        gender: gender
      };
    },
    // 转换为 18 位身份证号码
    convertTo18IdCard(idCard) {
      if (idCard.length === 18) {
        return idCard;
      }
      const century = new Date().getFullYear() % 100;
      const year = parseInt(idCard.slice(6, 8));
      let prefix = '';

      if (year <= century) {
        prefix = '20';
      } else {
        prefix = '19';
      }
      return idCard.slice(0, 6) + prefix + idCard.slice(6);
    },
    getaddUserContact() {
      const regex = /^1[3456789]\d{9}$/
      this.info.userId = this.$store.state.userid

      if (this.$verification(this.info.name)) {
        return this.$message.error('请输入姓名')
      }
      if (this.$verification(this.info.sex)) {
        return this.$message.error('请选择性别')
      }
      if (this.$verification(this.info.certificateType)) {
        return this.$message.error('请选择证件类型')
      }
      if (this.$verification(this.info.idCard)) {
        return this.$message.error('请输入证件号码')
      }
      if (this.info.certificateType == '01') {
        if (!this.validateIdCard(this.info.idCard)) {
          return this.$message.error('证件号码格式错误')
        }
        if (this.getBirthdayAndGender(this.info.idCard).birthday != this.info.birthday) {
          return this.$message.error('出生日期与身份证上不符合')
        }
        if (this.getBirthdayAndGender(this.info.idCard).gender != this.info.sex) {
          return this.$message.error('性别与身份证实际不符合')
        }
      }
      if (this.$verification(this.info.birthday)) {
        return this.$message.error('请输入选择出生日期')
      }
      if (this.$verification(this.info.phone)) {
        return this.$message.error('请输入手机号')
      }
      if (!regex.test(this.info.phone)) {
        return this.$message.error('手机号码格式错误')
      }

      if (this.editdistinction == 1) {
        this.$api.addUserContact(this.info).then(res => {
          this.$message.success('添加成功')
          this.handleCloses()
          this.getUserContact()
        })
      } else {
        this.$api.saveUserContact(this.info).then(res => {
          this.$message.success('编辑成功')
          this.handleCloses()
          this.getUserContact()
        })
      }
    },
    getdelete(row) {
      this.$alert('是否确认删除', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: action => {
          this.$api.removeUserContact({ id: row.id }).then(res => {
            this.$message.success('删除成功')
            this.getUserContact()
          })
        }
      });
    }
  },
  created() {

  },
  mounted() {
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>

<style lang='scss' scoped>
.box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .but {
    width: 300PX;
    height: 50PX;
    font-size: 18PX;
    border-radius: 4PX;
    color: #0A6DF3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1PX solid #0A6DF3;
    cursor: pointer;

    span {
      margin-left: 15PX;
    }
  }
}

.group {
  margin-top: 40PX;
}

::v-deep .el-checkbox-group {
  display: flex;
  flex-direction: column;
}

::v-deep .el-checkbox__label {
  font-size: 18PX;
}

::v-deep .el-checkbox__inner {
  width: 17PX;
  height: 17PX;
  margin-top: -2PX;
}

::v-deep .el-checkbox {
  margin-bottom: 20PX;
}

::v-deep .el-checkbox__inner::after {
  left: 5PX;
  top: 2PX;
}

.group ::v-deep.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0066FF;
}

.group ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #0066FF;
  background: #0066FF;
  margin-top: -2PX;
}

.but_box {
  display: flex;
  color: #fff;
  font-size: 20PX;
  display: flex;
  justify-content: center;
  margin-top: 50PX;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .zou {
    width: 300PX;
    height: 50PX;
    background: #a2bad5;
    margin-right: 40PX;
    cursor: pointer;
  }

  .you {
    width: 300PX;
    height: 50PX;
    background: linear-gradient(90deg, #6FB2FF, #095FFF);
    cursor: pointer;
  }
}

.boxs {
  display: flex;
  align-items: center;
  margin-bottom: 20PX;

  .img {
    width: 18PX;
    height: 15PX;
    margin-right: 20PX;
    margin-top: -5PX;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    width: 150PX;
    height: 40PX;
    font-size: 15PX;
    color: #000;
    line-height: 40PX;
  }

  .xx {
    color: red;
    margin-left: 5PX;
  }

  .input {
    width: 100%;
    display: flex;
  }

  .el-select {
    width: 100%;
  }

  .me {
    width: 220PX;
    height: 40PX;
    background: #FFFFFF;
    border-radius: 3PX;
    border: 1PX solid #0A6DF3;
    color: #0A6DF3;
    font-size: 15PX;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10PX;
    cursor: pointer;

    img {
      width: 25PX;
      height: 25PX;
      margin-right: 10PX;
    }
  }
}

.boxb {
  margin: 20PX 40PX;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

::v-deep .el-radio__label {
  display: block !important;
}

::v-deep .el-radio-group {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 10PX;

    .el-radio__label {
      font-size: 18PX;
    }
  }
}
</style>