<template>
  <div>
    <el-dialog title="报名" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" top="5vh">
      <div class="box">
        <div v-for="(item, index) in info.info" :key="index">
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/project2.png" alt="">
            </div>
            <div class="title">组别</div>
            <div class="input">
              <el-input placeholder="请输入内容" :disabled="true" v-model="item.groupName"></el-input>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/group.png" alt="">
            </div>
            <div class="title">项目</div>
            <div class="input">
              <el-input placeholder="请输入内容" :disabled="true" v-model="item.projectName"></el-input>
            </div>
          </div>
          <div class="boxs" v-if="isOpenNotes">
            <div class="img">
              <img src="../assets/image/message.png" alt="">
            </div>
            <el-tooltip class="item" effect="dark" :content="notesRemark" placement="top-start">
              <div class="title">{{ notesRemark }}</div>
            </el-tooltip>
            <div class="input">
              <el-input placeholder="请输入内容" v-model="item.projectRemark"></el-input>
            </div>
          </div>
          <div class="boxs" v-if="isOpenSportNotes">
            <div class="img">
              <img src="../assets/image/message.png" alt="">
            </div>
            <el-tooltip class="item" effect="dark" :content="sportNotesRemark" placement="top-start">
              <div class="title">{{ sportNotesRemark }}</div>
            </el-tooltip>
            <div class="input">
              <el-input placeholder="请输入内容" v-model="item.sportsmanRemark"></el-input>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/user.png" alt="">
            </div>
            <div class="title">姓名<span class="xx">*</span></div>
            <div class="input">
              <el-input placeholder="请输入内容" v-model="item.sportsmanName"></el-input>
              <div class="me" @click="getme(index)">
                <img src="../assets/image/me.png" alt="">
                <span>常用报名人</span>
              </div>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/gender.png" alt="">
            </div>
            <div class="title">性别<span class="xx">*</span></div>
            <div class="input">
              <el-select placeholder="请选择" clearable v-model="item.sportsmanSex">
                <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/ID.png" alt="">
            </div>
            <div class="title">证件类型<span class="xx">*</span></div>
            <div class="input">
              <el-select placeholder="请选择" v-model="item.sportsmanCardType">
                <el-option v-for="item in idCardType" :key="item.itemValue" :label="item.itemText"
                  :value="item.itemValue">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/ID1.png" alt="">
            </div>
            <div class="title">证件号码<span class="xx">*</span></div>
            <div class="input">
              <el-input placeholder="请输入内容" v-model="item.sportsmanCardNumber" @blur="getcardnum(index)"
                @input="formatIdCard(index)"></el-input>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/222.png" alt="">
            </div>
            <div class="title">出生日期<span class="xx">*</span></div>
            <div class="input">
              <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                v-model="item.sportsmanBirthday">
              </el-date-picker>
            </div>
          </div>
          <div class="boxs">
            <div class="img" style="width: 10PX;margin-right: 28PX;">
              <img src="../assets/image/tel.png" alt="">
            </div>
            <div class="title">手机号<span class="xx">*</span></div>
            <div class="input">
              <el-input placeholder="请输入内容" v-model="item.sportsmanPhone"></el-input>
            </div>
          </div>
          <div class="boxs">
            <div class="img">
              <img src="../assets/image/upload1.png" alt="">
            </div>
            <div class="title">上传附件</div>
            <div class="input">
              <el-upload class="upload-demo" :data="upData" :action="uploadUrl"
                :on-success="(response, file) => success(response, file, index)"
                :on-remove="(file, fileList) => removeFile(file, fileList, index)" multiple :limit="3"
                :headers="headers" :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </div>
          </div>
          <div class="xian"></div>
        </div>

        <!-- <div class="radio">
          <el-radio label="1" v-model="radio"></el-radio>
          <div>
            <p>已阅读同意<a class="jumpto" :href="VUE_APP_BASE_PRIVACYS" target="_blank">免责协议</a></p>
          </div>
        </div> -->

        <div class="but_box">
          <div class="zou" @click="preservation">保存</div>
          <div class="you" @click="Submit">提交</div>
        </div>
      </div>
    </el-dialog>

    <newlyadded ref="confirm" @confirm="confirm"></newlyadded>
  </div>
</template>

<script>
import newlyadded from '@/components/newlyadded.vue'
export default {
  components: {
    newlyadded
  },
  props: {
    distinguish: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      idCardType: [],
      gender: [{
        label: '男',
        value: 1
      },
      {
        label: '女',
        value: 2
      }],
      fileList: [],
      upData: {
        fileExtensionList: ["jpg", "png"],
      },
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/upload',
      headers: {
        "token": this.$store.state.token
      },
      radio: '0',
      VUE_APP_BASE_PRIVACYS: process.env.VUE_APP_BASE_PRIVACYS,

      isOpenNotes: false,
      isOpenSportNotes: false,
      notesRemark: '',
      sportNotesRemark: '',

      info: {
        userId: this.$store.state.userid,
        matchId: "",
        settingsId: "",
        status: 0,
        info: []
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getIdCardType() {
      this.$api.idCardType({ dictValue: '其他' }).then(res => {
        this.idCardType = res.data.data.result
      })
    },
    getBasicEnrollInfoByMatchId(id) {
      this.$api.getBasicEnrollInfoByMatchId(id).then(res => {
        this.isOpenNotes = res.data.data.result.isOpenNotes
        this.isOpenSportNotes = res.data.data.result.isOpenSportNotes
        this.notesRemark = res.data.data.result.notesRemark
        this.sportNotesRemark = res.data.data.result.sportNotesRemark
      })
    },
    isChineseTextContains(text, target) {
      let regExp = new RegExp(target, 'g');
      return regExp.test(text);
    },
    open(row) {
      this.info.matchId = row.matchId
      this.info.settingsId = row.settingsId
      let obj = {
        projectId: row.projectId,
        projectSettingId: row.projectSettingId,
        groupSettingId: row.groupSettingId,
        sportsmanName: '',
        sportsmanSex: '',
        sportsmanBirthday: '',
        sportsmanCardType: '01',
        sportsmanCardNumber: '',
        sportsmanPhone: '',
        fileList: [],
        projectRemark: '',
        sportsmanRemark: '',
        groupName: row.groupName,
        projectName: row.projectName,
        id: null
      }
      this.info.info.push({ ...obj });
      if (this.isChineseTextContains(row.projectName, '双打')) {
        this.info.info.push({ ...obj });
      }
      this.dialogVisible = true
      this.getBasicEnrollInfoByMatchId(row.matchId)
    },
    handleClose() {
      this.dialogVisible = false
      this.info = {
        userId: this.$store.state.userid,
        matchId: "",
        settingsId: "",
        status: 0,
        info: []
      }
      this.radio = 0
      this.fileList = []
    },
    success(response, file, index) {
      if (!this.info.info[index].fileList) {
        this.$set(this.info.info[index], 'fileList', []);
      }

      let newFile = {
        fileName: file.name,
        filePath: response.data.message,
        id: null
      };

      let newFileList = [...this.info.info[index].fileList];
      newFileList.push(newFile);

      this.$set(this.info.info[index], 'fileList', newFileList);
    },
    removeFile(file, fileList, index) {
      const removeFileIndex = this.info.info[index].fileList.findIndex(
        item => item.fileName === file.name
      );
      if (removeFileIndex !== -1) {
        this.info.info[index].fileList.splice(removeFileIndex, 1);
      }
    },
    getme(row) {
      this.$refs.confirm.open(row)
    },
    confirm(row) {
      this.info.info[row.index].sportsmanName = row.data.name
      this.info.info[row.index].sportsmanSex = row.data.sex
      this.info.info[row.index].sportsmanBirthday = row.data.birthday
      this.info.info[row.index].sportsmanCardType = row.data.certificateType
      this.info.info[row.index].sportsmanCardNumber = row.data.idCard
      this.info.info[row.index].sportsmanPhone = row.data.phone
    },
    // 验证身份证是否正确
    validateIdCard(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return false
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return false
      }

      // 统一转换为 18 位身份证号码
      let idCard18 = idCard;
      if (idCard.length === 15) {
        idCard18 = this.convertTo18IdCard(idCard);
      }

      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard18.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard18[17] !== checkCode[checkSum]) {
        return false
      }

      // 验证通过
      return true
    },
    // 验证身份证 出生日期 性别 
    getBirthdayAndGender(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return null;
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return null;
      }

      // 统一转换为 18 位身份证号码
      if (idCard.length === 15) {
        idCard = this.convertTo18IdCard(idCard);
      }
      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard[17] !== checkCode[checkSum]) {
        return null;
      }

      // 提取出生日期
      const birthDate = idCard.slice(6, 14);
      if (!/^\d{8}$/.test(birthDate)) {
        return null;
      }
      const year = parseInt(birthDate.slice(0, 4));
      const month = parseInt(birthDate.slice(4, 6));
      const day = parseInt(birthDate.slice(6, 8));

      // 提取性别
      const genderCode = parseInt(idCard[16]);
      const gender = genderCode % 2 === 0 ? 2 : 1;

      // 返回生日和性别信息
      return {
        birthday: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
        gender: gender
      };
    },
    // 转换为 18 位身份证号码
    convertTo18IdCard(idCard) {
      if (idCard.length === 18) {
        return idCard;
      }
      const century = new Date().getFullYear() % 100;
      const year = parseInt(idCard.slice(6, 8));
      let prefix = '';

      if (year <= century) {
        prefix = '20';
      } else {
        prefix = '19';
      }
      return idCard.slice(0, 6) + prefix + idCard.slice(6);
    },
    formatIdCard(e) {
      this.info.info[e].sportsmanCardNumber = this.info.info[e].sportsmanCardNumber.replace(/x/gi, 'X')
    },
    getcardnum(e) {
      if (this.info.info[e].sportsmanCardType == '01') {
        this.info.info[e].sportsmanBirthday = this.getBirthdayAndGender(this.info.info[e].sportsmanCardNumber).birthday
        this.info.info[e].sportsmanSex = this.getBirthdayAndGender(this.info.info[e].sportsmanCardNumber).gender
      }
    },
    preservation() {
      this.info.status = 0;
      const regex = /^1[3456789]\d{9}$/;
      let errorMessage = '';

      const hasError = this.info.info.some(item => {
        if (this.$verification(item.sportsmanName)) {
          errorMessage = '请输入姓名';
          return true;
        }
        if (this.$verification(item.sportsmanSex)) {
          errorMessage = '请选择性别';
          return true;
        }
        if (this.$verification(item.sportsmanCardType)) {
          errorMessage = '请选择证件类型';
          return true;
        }
        if (this.$verification(item.sportsmanCardNumber)) {
          errorMessage = '请输入证件号码';
          return true;
        }
        if (item.sportsmanCardType == '01') {
          if (!this.validateIdCard(item.sportsmanCardNumber)) {
            errorMessage = '证件号码格式错误';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).birthday != item.sportsmanBirthday) {
            errorMessage = '出生日期与身份证上不符合';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).gender != item.sportsmanSex) {
            errorMessage = '性别与身份证实际不符合';
            return true;
          }
        }
        if (this.$verification(item.sportsmanBirthday)) {
          errorMessage = '请选择出生日期';
          return true;
        }
        if (this.$verification(item.sportsmanPhone)) {
          errorMessage = '请输入手机号';
          return true;
        }
        if (!regex.test(item.sportsmanPhone)) {
          errorMessage = '手机号码格式错误';
          return true;
        }
        return false;
      });

      if (hasError) {
        this.$message.error(errorMessage);
        return;
      }

      // if (this.radio == 0) {
      //   this.$message.error('请同意免责协议');
      //   return;
      // }

      let data = JSON.parse(JSON.stringify(this.info));
      data.info.forEach(item => {
        item.sportsmanName = this.$encrypt(item.sportsmanName);
        item.sportsmanCardNumber = this.$encrypt(item.sportsmanCardNumber);
        item.sportsmanPhone = this.$encrypt(item.sportsmanPhone);
      });

      this.$api.projectEnroll(data).then(res => {
        this.$message.success('保存成功');
        this.handleClose();
      });

    },
    Submit() {
      this.info.status = 1;
      const regex = /^1[3456789]\d{9}$/;
      let errorMessage = '';

      const hasError = this.info.info.some(item => {
        if (this.$verification(item.sportsmanName)) {
          errorMessage = '请输入姓名';
          return true;
        }
        if (this.$verification(item.sportsmanSex)) {
          errorMessage = '请选择性别';
          return true;
        }
        if (this.$verification(item.sportsmanCardType)) {
          errorMessage = '请选择证件类型';
          return true;
        }
        if (this.$verification(item.sportsmanCardNumber)) {
          errorMessage = '请输入证件号码';
          return true;
        }
        if (item.sportsmanCardType == '01') {
          if (!this.validateIdCard(item.sportsmanCardNumber)) {
            errorMessage = '证件号码格式错误';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).birthday != item.sportsmanBirthday) {
            errorMessage = '出生日期与身份证上不符合';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).gender != item.sportsmanSex) {
            errorMessage = '性别与身份证实际不符合';
            return true;
          }
        }
        if (this.$verification(item.sportsmanBirthday)) {
          errorMessage = '请选择出生日期';
          return true;
        }
        if (this.$verification(item.sportsmanPhone)) {
          errorMessage = '请输入手机号';
          return true;
        }
        if (!regex.test(item.sportsmanPhone)) {
          errorMessage = '手机号码格式错误';
          return true;
        }
        return false;
      });

      if (hasError) {
        this.$message.error(errorMessage);
        return;
      }

      // if (this.radio == 0) {
      //   this.$message.error('请同意免责协议');
      //   return;
      // }

      let data = JSON.parse(JSON.stringify(this.info));
      data.info.forEach(item => {
        item.sportsmanName = this.$encrypt(item.sportsmanName);
        item.sportsmanCardNumber = this.$encrypt(item.sportsmanCardNumber);
        item.sportsmanPhone = this.$encrypt(item.sportsmanPhone);
      });

      this.$api.projectEnroll(data).then(res => {
        this.$message.success('报名成功');
        this.handleClose();
      });
    }
  },
  created() {
    this.getIdCardType()
  },
  mounted() {

  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>

<style lang='scss' scoped>
.box {
  margin: 40PX;

  .boxs {
    display: flex;
    align-items: center;
    margin-bottom: 20PX;

    .img {
      width: 18PX;
      height: 15PX;
      margin-right: 20PX;
      margin-top: -5PX;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      width: 150PX;
      height: 40PX;
      font-size: 15PX;
      color: #000;
      line-height: 40PX;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .xx {
      color: red;
      margin-left: 5PX;
    }

    .input {
      width: 100%;
      display: flex;
    }

    .el-select {
      width: 100%;
    }

    .me {
      width: 220PX;
      height: 40PX;
      background: #FFFFFF;
      border-radius: 3PX;
      border: 1PX solid #0A6DF3;
      color: #0A6DF3;
      font-size: 15PX;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10PX;
      cursor: pointer;

      img {
        width: 25PX;
        height: 25PX;
        margin-right: 10PX;
      }
    }
  }
}

.el-button--primary {
  background: #348AFE;
  border-color: #348AFE;
}

.xian {
  height: 1PX;
  width: 100%;
  background: rgba(22, 75, 146, 0.3);
  margin-bottom: 20PX;
}

::v-deep .el-dialog__header {
  background: #0066FF;
  border-top-left-radius: 10PX;
  border-top-right-radius: 10PX;

  .el-dialog__title {
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
}

::v-deep .el-dialog {
  border-radius: 10PX;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 30PX;
}

.radio p {
  font-size: 15PX;
  font-weight: 400;
  color: #333333;
}

.radio a {
  color: #0066FF;
}

::v-deep .el-radio {
  margin-right: 15PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #0066FF;
  background: #0066FF;
}

::v-deep .el-radio__inner {
  width: 17PX;
  height: 17PX;
}

::v-deep .el-radio__label {
  display: none;
}

.but_box {
  display: flex;
  color: #fff;
  font-size: 20PX;
  display: flex;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .zou {
    width: 300PX;
    height: 50PX;
    background: #a2bad5;
    margin-right: 40PX;
    cursor: pointer;
  }

  .you {
    width: 300PX;
    height: 50PX;
    background: linear-gradient(90deg, #6FB2FF, #095FFF);
    cursor: pointer;
  }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.jumpto {
  cursor: pointer;
}
</style>